@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');a{
  color: #6E6E6E;
}

p{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 86% */
  color: #6E6E6E;
}
body{
  background: #F7F7F7;
    font-size: 16px;
    font-family: Poppins,sans-serif;
    @media only screen and (min-width: 1000px) {
      background-color: #F7F7F7;
  }
}
textarea {
   font-family: Poppins,sans-serif !important;
}

.swal2-icon.swal2-info{
  border-color: #fff !important;
  color: #fff !important;
  background-color: #ff3000;
}
.swal2-icon{
  width:58px !important;
  height: 58px !important;
  margin-top: 0 !important;
}
.swal2-icon .swal2-icon-content{
  font-size: 40px !important;
    font-weight: 800 !important;
    animation: none !important;
}

.swal2-styled.swal2-confirm {
  border: 0;
  width: 250px !important;
  border-radius: 500px  !important;
  background: initial;
  color: #fff;
  font-size: 18px !important;
  font-weight: 600;
  font-family: Poppins,sans-serif !important;
}
.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  overflow: auto;
  font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    text-align: center;
    color: #6e6e6e !important;
    padding: 10px !important;
  word-wrap: break-word;
  word-break: break-word;
}

.swal2-container.swal2-center>.swal2-popup {
  outline: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
  display: block;
  -webkit-text-decoration: none !important;
  text-decoration: none;
  box-sizing: border-box !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 28px 23px -7px, rgb(0 0 0 / 4%) 0px 12px 12px -7px !important;
  position: relative !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  height: auto !important;
  width: auto !important;
  max-width: 360px !important;
  min-width: 300px !important;
  margin: 10px !important;
  z-index: 500 !important;
  padding: 20px !important;
}

.swal2-container{
  opacity: 1 !important;
}
.css-1li2koq {
  height: 100% !important;
}

.mantine-DatePicker-dropdown{
  display:none !important;
}
.mantine-bhsibn {
  gap: 11px !important;
  /* flex-flow: row; */
}